import { isNil, startCase } from 'lodash';
import { createSelector } from 'reselect';

import {
    getRequisitionStatusData,
    requisitionFiltersNames,
} from '@og-pro/shared-config/requisitions';

import {
    getLiveUserRequisitionGroups,
    getLeaderUserRequisitionGroups,
    getReviewSequences,
    getRequestTypes,
} from '../../../../selectors/govApp';
import { getUserJS, isRequisitionGlobalEditorUser } from '../../../selectors';
import { getRequestsColorByThresholds, getUniqueCreatorsFromRequisitionGroups } from './helpers';
import { initialCheckboxFilters } from './List/Filters/constants';

const getRawRequisitions = (state) => state.requisitionsDashboard.get('list');
const getRawRequisitionsSummary = (state) => state.requisitionsDashboard.get('summary');

export const getIsSearching = (state) => state.requisitionsDashboard.get('searching');
export const getSearchError = (state) => state.requisitionsDashboard.get('searchError');

export const getIsLoading = (state) =>
    state.requisitionsDashboard.get('loadingRequisitionsSummary');
export const getLoadError = (state) =>
    state.requisitionsDashboard.get('loadRequisitionsSummaryError');

export const getRequisitionsSearchResults = createSelector(
    [getRawRequisitions],
    (rawRequisitions) => {
        return rawRequisitions.toJS().requisitions;
    }
);

export const getRequisitionsSearchCount = createSelector(
    [getRawRequisitions],
    (rawRequisitions) => {
        return rawRequisitions.toJS().count;
    }
);

export const getRequisitionsPath = ({ params }) => {
    const { governmentId } = params;
    return `/governments/${governmentId}/requisitions`;
};

export const getRequisitionsSummaryJS = createSelector(
    [getRawRequisitionsSummary],
    (rawRequisitionsSummary) => {
        if (!rawRequisitionsSummary) {
            return null;
        }

        const summary = rawRequisitionsSummary.toJS();

        if (
            !summary ||
            !summary.numberOfRequestsData ||
            !summary.activeRequestsData ||
            !summary.requestsNeedingAttentionData
        ) {
            return null;
        }

        const formattedSummary = {
            numberOfRequestsData: summary.numberOfRequestsData.map((data) => {
                return {
                    name: `${data.period} days`,
                    y: data.count,
                };
            }),
            activeRequestsData: summary.activeRequestsData.map((data) => {
                return {
                    name: getRequisitionStatusData(data.status).longName,
                    y: data.count,
                    status: data.status,
                };
            }),
            requestsNeedingAttentionData: Object.keys(summary.requestsNeedingAttentionData)
                .map((key) => {
                    const value = summary.requestsNeedingAttentionData[key];

                    if (isNil(value)) {
                        return null;
                    }

                    return {
                        value: key,
                        name: startCase(key),
                        y: value,
                        color: getRequestsColorByThresholds(key, value),
                    };
                })
                .filter(Boolean),
        };
        return formattedSummary;
    }
);

export const getRequisitionsTabsData = createSelector(
    [getRawRequisitionsSummary],
    (rawRequisitionsSummary) => {
        const requisitionsSummary = rawRequisitionsSummary?.toJS();

        return requisitionsSummary?.tabsData || null;
    }
);
export const getIsGroupAdminOrLeader = createSelector(
    [getUserJS, getLiveUserRequisitionGroups, isRequisitionGlobalEditorUser],
    (user, requisitionGroups, isRequisitionGlobalEditor) => {
        if (isRequisitionGlobalEditor) {
            return true;
        }

        if (!requisitionGroups || !requisitionGroups.length) {
            return false;
        }

        return requisitionGroups.some((requisitionGroup) => {
            return requisitionGroup.leaders.some((leader) => leader.id === user.id);
        });
    }
);

export const getIsGroupAdminOrMultipleGroupsLeader = createSelector(
    [getUserJS, getLiveUserRequisitionGroups, isRequisitionGlobalEditorUser],
    (user, requisitionGroups, isRequisitionGlobalEditor) => {
        if (isRequisitionGlobalEditor) {
            return true;
        }

        if (!requisitionGroups || !requisitionGroups.length) {
            return false;
        }

        // check if user is leader of more than 1 group
        let leaderCount = 0;
        requisitionGroups.forEach((requisitionGroup) => {
            requisitionGroup.leaders.forEach((leader) => {
                if (leader.id === user.id) {
                    leaderCount++;
                }
            });
        });

        return leaderCount > 1;
    }
);

const { REVIEW_GROUPS, CREATORS, EXCEPTION_REVIEW_SEQUENCES, REQUEST_TYPES } =
    requisitionFiltersNames;

export const getCheckboxFilters = createSelector(
    [
        getLeaderUserRequisitionGroups,
        getReviewSequences,
        getIsGroupAdminOrMultipleGroupsLeader,
        getRequestTypes,
    ],
    (requisitionGroups, reviewSequences, isGroupAdminOrMultipleGroupsLeader, requestTypes) => {
        const initialFilters = { ...initialCheckboxFilters };

        if (!isGroupAdminOrMultipleGroupsLeader) {
            delete initialFilters[REVIEW_GROUPS];
        } else {
            initialFilters[REVIEW_GROUPS].options = requisitionGroups.map((requisitionGroup) => {
                return {
                    label: requisitionGroup.name,
                    value: requisitionGroup.id.toString(),
                };
            });
        }

        initialFilters[CREATORS].options =
            getUniqueCreatorsFromRequisitionGroups(requisitionGroups);

        initialFilters[EXCEPTION_REVIEW_SEQUENCES].options = reviewSequences
            .filter(({ isException }) => isException)
            .map((reviewSequence) => {
                return {
                    label: reviewSequence.name,
                    value: reviewSequence.id.toString(),
                };
            });

        initialFilters[REQUEST_TYPES].options = requestTypes.map((requestType) => {
            return {
                label: requestType.name,
                value: requestType.id.toString(),
            };
        });

        const filtersList = Object.keys(initialFilters).map((key) => ({
            ...initialFilters[key],
            filter: key,
        }));

        return filtersList.map((filter) => ({
            ...filter,
            options: filter.options.map((option) => ({
                ...option,
                filter: filter.filter,
            })),
        }));
    }
);
