const { statusTypes } = require('.');

exports.createMockRequisition = (dataValues) => ({
    review_group_id: 44,
    isOverBudget: false,
    last_step_id: null,
    flagResponses: [],
    reviewGroup: {
        icon: 'bolt',
        name: '',
        allowOverBudget: false,
        abbreviation: 'RG',
        creators: [],
    },
    isOnHold: false,
    current_step_id: null,
    requestor_id: 1,
    purchaseDetailsMode: 1,
    identifier: '',
    government_id: 1,
    created_at: '2024-01-19T01:11:57.906Z',
    vendors: [],
    creator: {},
    purchaseOrderObject: null,
    vendorSelectionState: 'selectedVendors',
    creator_id: 1,
    submittedAt: null,
    submitter_id: null,
    requestor: {
        displayName: 'Requestor',
        fullName: 'Requestor',
        initials: 'R',
        id: 1,
        avatarUrl: '',
        email: 'requestor@opengov.com',
        firstName: 'Requestor',
        lastName: 'Requestor',
    },
    additionalVendorNotes: null,
    status: statusTypes.DRAFT,
    canceler_id: null,
    updated_at: '2024-01-19T01:12:13.101Z',
    id: 1,
    rejectionNote: null,
    descriptionOfRequest: 'Mock Requisition',
    financialSystemIntegration: 1,
    fiscalPeriodObject: {},
    staticStepIds: null,
    fiscalPeriod: 'Fiscal Year 2024',
    closedAt: null,
    desiredDeliveryDate: '2024-07-18T05:00:00.000Z',
    expectedPurchaseOrderDate: '2023-12-20T22:34:32.882Z',
    priceTable: {
        priceItems: [
            {
                accountSplitPriceItems: [],
            },
        ],
    },
    requestType: {
        ...dataValues?.requestType,
    },
    customFormSnapshotVersion: 1,
    customFormId: 1,
    ...dataValues,
});
