import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Box, NoSsr } from '@og-pro/ui';
import { Link, useParams } from 'react-router-dom';
import { tokens } from '@opengov/capital-style';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { USE_PROCURATED } from '@og-pro/shared-config/procurated';
import { convertLanguagesToLanguageDisplayNames } from '@og-pro/shared-config/vendorLanguages';

import { getCategoryGroups, getVendorUploads } from './selectors';
import {
    Button,
    CategoryCodesLongForm,
    ContactAddress,
    HelpIcon,
    HorizontalProcuratedBox,
    Main,
    ProcuratedBox,
    SectionTitle,
} from '..';
import { Certification } from './Certification';
import { prependUrl } from '../../utils';

import { getUserJS } from '../../containers/selectors';
import Contacts from './Contacts';
import { listsInfoHelpText } from '../connected/OrganizationInfoForm/constants';

const { colors } = tokens;

const mapStateToProps = (state, props) => {
    return {
        categoryGroups: getCategoryGroups(props),
        vendorUploads: getVendorUploads(props),
        user: getUserJS(state),
    };
};

// @connect
const ConnectedVendorProfile = ({
    categoryGroups,
    isRatingEnabled = true,
    noTitle,
    publicView = false,
    showEdit,
    showShareableLink = true,
    useHorizontalProcuratedBox,
    user,
    vendor,
    vendor: {
        businessType,
        doingBusinessAs,
        duns,
        ein,
        id: vendorId,
        languages,
        organization: {
            address1,
            billingAddress1,
            billingAddress2,
            billingCity,
            billingCountry,
            billingCountryCode,
            billingState,
            billingZipCode,
            city,
            description,
            faxPhoneComplete,
            logo,
            name,
            phoneComplete,
            state,
            users,
            website,
            zipCode,
        },
        organization,
        stateOfIncorporation,
        vendorLists,
        vendorListsAllGovs, // public lists are here in vendor's company profile
        vendorListsPrivate: privateLists,
        uniqueEntityId,
    },
    vendorUploads,
}) => {
    const isFlagEnabled = useFlags(FLAGS.PUBLIC_VENDOR_SEARCH_PORTAL);
    const { governmentCode } = useParams();

    const styles = require('./VendorProfile.scss');

    const procuratedEnabled = USE_PROCURATED && isRatingEnabled;

    const showPrivateLists =
        !publicView &&
        user?.isVendor === false &&
        user?.government?.displayPublicVendorInformation === true;

    const publicLists = vendorListsAllGovs?.map((gov) => gov.lists).flat();

    const renderUploads = ({ attachments, title, type }) => {
        const noUploads = !attachments || attachments.length === 0;

        return (
            <div className={styles.sectionItem} key={type}>
                <div className="row">
                    <div className={`col-md-5 ${styles.label}`}>{title}</div>
                    <div className="col-md-7">
                        {noUploads
                            ? 'None'
                            : attachments.map((attachment) => (
                                  <div className={styles.attachment} key={attachment.id}>
                                      <a href={attachment.url}>{attachment.name}</a>
                                  </div>
                              ))}
                    </div>
                </div>
            </div>
        );
    };

    const renderAddress = (prefix) => {
        if (prefix === 'billing') {
            return (
                <ContactAddress
                    address1={billingAddress1}
                    address2={billingAddress2}
                    city={billingCity}
                    country={billingCountry}
                    countryCode={billingCountryCode}
                    state={billingState}
                    zipCode={billingZipCode}
                />
            );
        }

        if (!address1 || !city || !state || !zipCode) {
            return <>N/A</>;
        }
        return <ContactAddress {...organization} />;
    };

    const renderEditButton = () => {
        const editUrl = `/vendors/${vendorId}/profile/edit`;
        return (
            <div className="text-right">
                <Button bsSize="sm" bsStyle="link" qaTag="vendorProfile-edit" to={editUrl}>
                    <i className="fa fa-pencil" /> Edit Profile
                </Button>
            </div>
        );
    };

    const renderLanguages = () => {
        if (languages.length === 0) {
            return 'None specified';
        }

        return convertLanguagesToLanguageDisplayNames(languages);
    };

    const ref = useRef(null);
    const [isLarge, setIsLarge] = useState(false);

    useEffect(() => {
        if (ref && ref.current && ref.current.offsetWidth > 600) {
            setIsLarge(true);
        }
    }, []);

    const imageContainerStyle =
        isLarge && !useHorizontalProcuratedBox ? styles.imageContainer : styles.smallImageContainer;

    const renderProcuratedBox = () => {
        return useHorizontalProcuratedBox ? (
            <div className="col-xs-12 col-lg-10">
                <span className={styles.horizontalProcuratedBox}>
                    <HorizontalProcuratedBox supplierId={vendorId} />
                </span>
                <span className={styles.smallProcuratedBox}>
                    <ProcuratedBox supplierId={vendorId} />
                </span>
            </div>
        ) : (
            <ProcuratedBox isLarge={isLarge} supplierId={vendorId} vendorSelfManagement={isLarge} />
        );
    };

    const vendorCertifications = vendor.certifications
        .filter((certification) => certification.vendorCertifications)
        .flatMap((certification) => certification.vendorCertifications);

    const verifiedCertifications = vendorCertifications.filter(
        (cert) => cert.certification_source_id !== 1
    );
    const selfReportedCertifications = vendorCertifications.filter(
        (cert) => cert.certification_source_id === 1
    );

    const renderList = (list) => {
        if (!list || list?.length === 0) {
            return 'None specified';
        }
        return (
            <div className={styles.list}>
                {list.map((listItem, i) => (
                    <div key={`${listItem}-${i}`}>
                        <p className={styles.listTitle}>{listItem}</p>
                        {i < list.length - 1 && <hr className={styles.hr} />}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Main data-qa="vendorProfile-modal">
            {isFlagEnabled &&
                showShareableLink &&
                user?.isVendor !== true &&
                vendor?.id &&
                (user?.government?.code || governmentCode) && (
                    <Link
                        rel="noopener noreferrer"
                        target="_blank"
                        to={`/portal/${user?.government?.code ?? governmentCode}/vendors/${vendor.id}`}
                    >
                        <div className={styles.linkContainer}>
                            Go to Vendor Profile
                            <span className={styles.arrow}>»</span>
                        </div>
                    </Link>
                )}
            {showEdit && renderEditButton()}
            {!noTitle && (
                <SectionTitle className={styles.title} title={name} titlePlacement="left" />
            )}
            <div className={`text-center ${procuratedEnabled && styles.profileHeader}`} ref={ref}>
                <div
                    className={procuratedEnabled ? imageContainerStyle : styles.fullImageContainer}
                    style={{ backgroundImage: `url(${logo})` }}
                    title={`${name} profile image`}
                />
                {procuratedEnabled && renderProcuratedBox()}
            </div>
            <NoSsr>
                <div className={styles.section}>
                    <div className={styles.sectionHeader}>COMPANY INFORMATION</div>
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>Legal Vendor Name</div>
                            <div className="col-md-7">{organization.name || 'N/A'}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>
                                Doing Business As (DBA) Name
                            </div>
                            <div className="col-md-7">{doingBusinessAs || 'None'}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>Company Phone Number</div>
                            <div className="col-md-7">{phoneComplete || 'N/A'}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>Company Address</div>
                            <div className="col-md-7">{renderAddress()}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>Company Website</div>
                            <div className="col-md-7">
                                {website ? (
                                    <a
                                        href={prependUrl(website)}
                                        rel="noreferrer noopener"
                                        target="_blank"
                                    >
                                        {website}
                                    </a>
                                ) : (
                                    'N/A'
                                )}
                            </div>
                        </div>
                    </div>
                    {users && (
                        <div className={styles.sectionItem}>
                            <div className="row">
                                <div className={`col-md-5 ${styles.label}`}>Contacts</div>
                                <div className="col-md-7">
                                    <Contacts
                                        contacts={users.filter(
                                            (myUser) => myUser.status === 'active'
                                        )}
                                        showCopyEmail
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>Languages</div>
                            <div className="col-md-7">{renderLanguages()}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>Company Description</div>
                            <div className="col-md-7">{description || 'None'}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>Business Type</div>
                            <div className="col-md-7">{businessType || 'N/A'}</div>
                        </div>
                    </div>
                    {billingAddress1 && !publicView && (
                        <div className={styles.sectionItem}>
                            <div className="row">
                                <div className={`col-md-5 ${styles.label}`}>Billing Address</div>
                                <div className="col-md-7">{renderAddress('billing')}</div>
                            </div>
                        </div>
                    )}
                    {faxPhoneComplete && (
                        <div className={styles.sectionItem}>
                            <div className="row">
                                <div className={`col-md-5 ${styles.label}`}>Fax Number</div>
                                <div className="col-md-7">{faxPhoneComplete}</div>
                            </div>
                        </div>
                    )}
                    <div className={styles.sectionItem}>
                        <div className="row">
                            <div className={`col-md-5 ${styles.label}`}>State of Incorporation</div>
                            <div className="col-md-7">{stateOfIncorporation || 'N/A'}</div>
                        </div>
                    </div>
                    {!publicView && (
                        <>
                            <div className={styles.sectionItem}>
                                <div className="row">
                                    <div className={`col-md-5 ${styles.label}`}>
                                        Employer Identification Number (EIN)
                                    </div>
                                    <div className="col-md-7">{ein || 'None'}</div>
                                </div>
                            </div>
                            <div className={styles.sectionItem}>
                                <div className="row">
                                    <div className={`col-md-5 ${styles.label}`}>
                                        Unique Entity Identifier (UEI)
                                    </div>
                                    <div className="col-md-7">{uniqueEntityId || 'None'}</div>
                                </div>
                            </div>
                            <div className={styles.sectionItem}>
                                <div className="row">
                                    <div className={`col-md-5 ${styles.label}`}>
                                        Dun & Bradstreet Number (DUNS)
                                    </div>
                                    <div className="col-md-7">{duns || 'None'}</div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                        LISTS
                        <HelpIcon
                            placement="right"
                            style={{
                                color: colors.colorGray800,
                                width: 16,
                                height: 16,
                                marginLeft: 8,
                                marginBottom: -3,
                            }}
                            tooltip={listsInfoHelpText(organization.name || 'N/A')}
                            useOpenGovStyle
                        />
                    </div>
                    {(vendorLists?.length > 0 || publicLists?.length > 0) && (
                        <div className={styles.sectionItem}>
                            <div className="row">
                                <div className={`col-md-5 ${styles.label}`}>
                                    {showPrivateLists ? 'Public Lists' : 'Lists'}
                                </div>
                                <div className="col-md-7">
                                    {renderList(vendorLists ?? publicLists)}
                                </div>
                            </div>
                        </div>
                    )}
                    {showPrivateLists && privateLists?.length > 0 && (
                        <div className={styles.sectionItem}>
                            <div className="row">
                                <div className={`col-md-5 ${styles.label}`}>Private Lists</div>
                                <div className="col-md-7">{renderList(privateLists)}</div>
                            </div>
                        </div>
                    )}
                    {vendorLists?.length === 0 &&
                        publicLists?.length === 0 &&
                        !showPrivateLists && (
                            <div className={styles.sectionItem}>
                                <div className="row">
                                    <div className="col-md-7 col-md-offset-5">None specified</div>
                                </div>
                            </div>
                        )}
                </div>
                <div className={styles.section}>
                    <div className={styles.sectionHeader}>CERTIFICATIONS</div>
                    <Box className={styles.certifications} display="flex" flexDirection="column">
                        <Box>
                            <div className={styles.sectionItem}>
                                <div className={styles.certificationTitle}>
                                    Verified Certifications
                                </div>
                            </div>
                            {verifiedCertifications.length > 0 ? (
                                <Box
                                    className={styles.certificationList}
                                    display="flex"
                                    flexDirection="column"
                                >
                                    {verifiedCertifications.map((certification) => (
                                        <div className={styles.sectionItem}>
                                            <Certification
                                                certification={certification}
                                                isVerified
                                                key={certification.id}
                                            />
                                        </div>
                                    ))}
                                </Box>
                            ) : (
                                <div className={styles.sectionItem}>
                                    <div className="row">
                                        <div className="col-md-7 col-md-offset-5">
                                            None specified
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Box>
                        <Box>
                            <div className={styles.sectionItem}>
                                <div className={styles.certificationTitle}>
                                    Self Reported Certifications
                                </div>
                            </div>
                            {selfReportedCertifications.length > 0 ? (
                                <Box
                                    className={styles.certificationList}
                                    display="flex"
                                    flexDirection="column"
                                >
                                    {selfReportedCertifications.map((certification) => (
                                        <div className={styles.sectionItem}>
                                            <Certification
                                                certification={certification}
                                                key={certification.id}
                                            />
                                        </div>
                                    ))}
                                </Box>
                            ) : (
                                <div className={styles.sectionItem}>
                                    <div className="row">
                                        <div className="col-md-7 col-md-offset-5">
                                            None specified
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Box>
                    </Box>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionHeader}>CATEGORY CODES</div>
                    {categoryGroups.map((categoryGroup, i) => (
                        <div className={styles.sectionItem} key={`${categoryGroup.title}-${i}`}>
                            <div className="row">
                                <div className={`col-md-5 ${styles.label}`}>
                                    {categoryGroup.title}
                                </div>
                                <div className="col-md-7">
                                    <CategoryCodesLongForm
                                        codes={categoryGroup.categories}
                                        limit={5}
                                        noCategoriesText="None"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {!publicView && (
                    <div className={styles.section}>
                        <div className={styles.sectionHeader}>DOCUMENTS</div>
                        {vendorUploads.map((upload) => renderUploads(upload))}
                    </div>
                )}
            </NoSsr>
        </Main>
    );
};

ConnectedVendorProfile.propTypes = {
    categoryGroups: PropTypes.arrayOf(
        PropTypes.shape({
            categories: PropTypes.array.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    isRatingEnabled: PropTypes.bool,
    noTitle: PropTypes.bool,
    publicView: PropTypes.bool,
    showEdit: PropTypes.bool,
    showShareableLink: PropTypes.bool,
    useHorizontalProcuratedBox: PropTypes.bool,
    user: PropTypes.object.isRequired,
    vendor: PropTypes.shape({
        businessType: PropTypes.string,
        certifications: PropTypes.array.isRequired,
        doingBusinessAs: PropTypes.string,
        duns: PropTypes.string,
        ein: PropTypes.string,
        emergencyDisaster: PropTypes.bool,
        emergencyHealth: PropTypes.bool,
        emergencyOther: PropTypes.bool,
        id: PropTypes.number.isRequired,
        languages: PropTypes.arrayOf(PropTypes.string).isRequired,
        noDbeDesignations: PropTypes.bool,
        organization: PropTypes.shape({
            address1: PropTypes.string,
            address2: PropTypes.string,
            billingAddress1: PropTypes.string,
            billingAddress2: PropTypes.string,
            billingCity: PropTypes.string,
            billingCountry: PropTypes.string,
            billingCountryCode: PropTypes.string,
            billingState: PropTypes.string,
            billingZipCode: PropTypes.string,
            city: PropTypes.string,
            description: PropTypes.string,
            faxPhoneComplete: PropTypes.string,
            logo: PropTypes.string,
            name: PropTypes.string.isRequired,
            phoneComplete: PropTypes.string,
            state: PropTypes.string,
            users: PropTypes.arrayOf(
                PropTypes.shape({
                    email: PropTypes.string.isRequired,
                    firstName: PropTypes.string.isRequired,
                    fullName: PropTypes.string.isRequired,
                    id: PropTypes.number.isRequired,
                    lastName: PropTypes.string.isRequired,
                    phone: PropTypes.string,
                    phoneComplete: PropTypes.string,
                    status: PropTypes.string,
                    title: PropTypes.string,
                })
            ).isRequired,
            website: PropTypes.string,
            zipCode: PropTypes.string,
        }),
        stateOfIncorporation: PropTypes.string,
        uniqueEntityId: PropTypes.string,
        vendorLists: PropTypes.arrayOf(PropTypes.string),
        vendorListsAllGovs: PropTypes.arrayOf(
            PropTypes.shape({
                govId: PropTypes.number,
                govName: PropTypes.string,
                lists: PropTypes.arrayOf(PropTypes.string),
            })
        ),
        vendorListsPrivate: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    vendorUploads: PropTypes.arrayOf(
        PropTypes.shape({
            attachments: PropTypes.array,
            title: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export const VendorProfile = connect(mapStateToProps)(ConnectedVendorProfile);
