import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import React, { useState } from 'react';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { Button, Typography } from '@og-pro/ui';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';

import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FORM,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    sectionsMap,
    VENDOR_SELECTION,
} from '../../../../../constants/requisitionsCreate';
import { sectionNames, sections } from './constants';

import {
    AdditionalInformation,
    GeneralInformation,
    PurchaseDetails,
    VendorSelection,
} from '../../../RequisitionsCreate/RequisitionsCreateForms/FormComponents';
import { RequisitionAttachment } from '../../../RequisitionsCreate/RequisitionsCreateForms/FormComponents/Attachments/RequisitionAttachment';
import { getRequisitionJS } from '../../../../../selectors/govApp';
import { formConfig } from '../../../RequisitionsCreate/RequisitionsCreateForms/form';
import { CustomFieldsSection } from './CustomFieldsSection';

const sectionComponents = (isSelected, setIsSelected) => ({
    [GENERAL_INFORMATION]: <GeneralInformation disabled />,
    [PURCHASE_DETAILS]: <PurchaseDetails disabled isDraft />,
    [VENDOR_SELECTION]: <VendorSelection disabled />,
    [CUSTOM_FORM]: <CustomFieldsSection isSelected={isSelected} setIsSelected={setIsSelected} />,
    [ADDITIONAL_INFORMATION]: <AdditionalInformation disabled />,
    [ATTACHMENTS]: <RequisitionAttachment enableScanning renderAsDropPanel />,
});

const AddSectionWithCustomFieldsButton = ({ onClick }) => {
    return (
        <Box
            alignItems="center"
            display="flex"
            gap={3}
            justifyContent="center"
            sx={{
                width: '100%',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray600}`,
                }}
            />
            <Button
                color="primary"
                onClick={onClick}
                startIcon={<AddIcon />}
                sx={{
                    paddingX: 2,
                    paddingY: 0,
                    textTransform: 'none',
                }}
                variant="text"
            >
                Add a Section with Custom Fields
            </Button>
            <Box
                sx={{
                    flex: 1,
                    borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray600}`,
                }}
            />
        </Box>
    );
};

AddSectionWithCustomFieldsButton.propTypes = {
    onClick: PropTypes.func,
};

// RequestTypeFormDisplay has to be wrapped in reduxForm to get the form values for the section components
// @reduxForm
export const ConnectedRequestTypeFormDisplay = ({
    selectedSection,
    setSelectedSection,
    customFormId,
}) => {
    const [showCustomForm, setShowCustomForm] = useState(!!customFormId);
    const requisition = useSelector(getRequisitionJS);

    function handleAddSectionWithCustomFieldsClick() {
        setShowCustomForm(true);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {sections
                .filter((section) => section !== sectionNames.FORM_SETTINGS) // Filter out the form settings section from the list since we render it separately
                .filter(
                    (section) =>
                        !requisition.requestType[sectionsMap[section]?.sectionHideKey] ||
                        section === CUSTOM_FORM
                )
                .map((sectionKey, idx) => {
                    const section = sectionsMap[sectionKey];
                    const isSelected = sectionKey === selectedSection;
                    const setIsSelected = () => {
                        setSelectedSection(sectionKey);
                    };
                    const component = sectionComponents(isSelected, setIsSelected)[sectionKey];
                    if (!component) {
                        return null;
                    }

                    const isCustomForm = sectionKey === CUSTOM_FORM;

                    if (isCustomForm && !showCustomForm) {
                        return (
                            <AddSectionWithCustomFieldsButton
                                key={CUSTOM_FORM}
                                onClick={handleAddSectionWithCustomFieldsClick}
                            />
                        );
                    }

                    function handleSelection() {
                        setSelectedSection(isSelected ? sectionNames.FORM_SETTINGS : sectionKey);
                    }

                    function getSectionNumber() {
                        const visibleSections = sections
                            .filter((s) => s !== sectionNames.FORM_SETTINGS) // Exclude FORM_SETTINGS
                            .filter(
                                (s) => !requisition.requestType[sectionsMap[s]?.sectionHideKey]
                            ); // Exclude hidden sections

                        if (!showCustomForm) {
                            return visibleSections.findIndex((s) => s === sectionKey) + 1;
                        }

                        return idx + 1;
                    }

                    return (
                        <Box
                            key={sectionKey}
                            onClick={() => handleSelection()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleSelection();
                                }
                            }}
                            role="button"
                            sx={{
                                background: capitalDesignTokens.foundations.colors.white,
                                borderRadius: 2,
                                border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                                borderLeft: isSelected
                                    ? `8px solid ${capitalDesignTokens.foundations.colors.brand100}`
                                    : undefined,
                                pb: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                ...(sectionKey !== CUSTOM_FORM && {
                                    // Allow the custom fields section to get clicks
                                    '& *:not([tabIndex])': { pointerEvents: 'none' },
                                }),
                            }}
                            tabIndex={0} // Makes the section focusable
                        >
                            <Box
                                sx={{
                                    pb: 3,
                                    borderBottom: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                                    gap: 2,
                                    display: 'flex',
                                    px: 4,
                                    pt: 4,
                                    background: isSelected
                                        ? '#F7F9FE'
                                        : capitalDesignTokens.foundations.colors.white,
                                }}
                            >
                                <Box sx={{ marginTop: '6px' }}>
                                    <Box
                                        sx={{
                                            borderRadius: '100%',
                                            background: isSelected
                                                ? capitalDesignTokens.semanticColors.foreground
                                                      .action
                                                : capitalDesignTokens.semanticColors.foreground
                                                      .secondary,
                                            height: '20px',
                                            width: '20px',
                                            color: capitalDesignTokens.foundations.colors.white,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography variant="h5">{getSectionNumber()}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography variant="h2">
                                        {requisition.requestType[section.sectionNameKey] ||
                                            'New Section'}
                                    </Typography>
                                    <Typography variant="bodyDefault">
                                        {requisition.requestType[section.sectionInstructionsKey] ||
                                            'Add instructional text for this section.'}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ pt: 4, px: 4 }}>{component}</Box>
                        </Box>
                    );
                })}
        </Box>
    );
};

ConnectedRequestTypeFormDisplay.propTypes = {
    selectedSection: PropTypes.string,
    setSelectedSection: PropTypes.func,
    customFormId: PropTypes.number,
};

export const RequestTypeFormDisplay = reduxForm({
    form: formConfig.form,
    enableReinitialize: true,
})(ConnectedRequestTypeFormDisplay);
