import { listToDict } from '@og-pro/shared-config/helpers';

import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FORM,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
} from '../../../../../constants/requisitionsCreate';

export const form = 'requestTypeEdit';

const fields = [
    'desiredDeliveryDate',
    'name',
    'endsInPurchaseOrder',
    'hideSectionAttachment',
    'hideSectionCustomForm',
    'hideSectionPurchase',
    'hideSectionVendor',
    'purchaseDetailsMode',
    'requireDescriptionOfRequest',
    'requireSummary',
    'sectionInstructionsAdditional',
    'sectionInstructionsAttachment',
    'sectionInstructionsCustomForm',
    'sectionInstructionsGeneral',
    'sectionInstructionsPurchase',
    'sectionInstructionsVendor',
    'sectionNameAdditional',
    'sectionNameAttachment',
    'sectionNameCustomForm',
    'sectionNameGeneral',
    'sectionNamePurchase',
    'sectionNameVendor',
    'showAccountInformation',
    'showBackground',
    'showCategories',
    'fieldsOrder',
    'requiredFields',
    'conditionalStatements',
];

export const fieldNames = listToDict(fields);

export const sections = [
    'formSettings',
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
    CUSTOM_FORM,
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
];

export const sectionNames = listToDict(sections);
